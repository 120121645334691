import React from "react";

export default function Indicators({ activePage }) {
  return (
    <ul className="indicators">
      <li className={activePage === 1 ? "active" : ""}>
        Scholarship Questions
      </li>
      <li className={activePage === 2 ? "active" : ""}>Introduce Yourself</li>
      <li className={activePage === 3 ? "active" : ""}>Results</li>
    </ul>
  );
}
