import React from "react";
import WhiteArrow from "../images/white-arrow.png";
import CaretDown from "../images/caret-down.svg";

export function Button({ type, value, onClick }) {
  return (
    <button className={type} onClick={onClick} disabled={type === "disabled"}>
      {value}
      {type === "primary" && <img src={WhiteArrow} alt="" />}
    </button>
  );
}

export function CheckBox({ name, value, label }) {
  return (
    <div className="checkbox-control">
      <label>
        <input type="checkbox" id={name} name={name} value={value} />
        {label}
      </label>
    </div>
  );
}

export function Select({ name, label, options, error }) {
  return (
    <div className="input-group">
      <label className="form-label">{label}</label>
      <select
        name={name}
        id={name}
        required
        style={{ backgroundImage: `url(${CaretDown})` }}
      >
        {Object.entries(options).map(([key, value], idx) => {
          return (
            <option value={value} key={idx}>
              {key}
            </option>
          );
        })}
      </select>
      {error && <span className="error">{error}</span>}
    </div>
  );
}

export function TextField({ name, label, error }) {
  return (
    <div className="input-group">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <input type="text" name={name} id={name} required />
      {error && <span className="error">{error}</span>}
    </div>
  );
}
