import React from "react";
import { Button } from "./Form";

export default function Results({ activePage, scholarshipResults }) {
  return (
    <div className={`form-page${activePage === 3 ? " active" : ""}`}>
      <h3>This is exciting!</h3>
      <div className="mb-32px">
        <p>
          You may be eligible for the following scholarships and amounts based
          upon your background, interests, and GPA.<sup>*</sup>
        </p>
        {scholarshipResults.scholarships[0] === "21st Century Scholar" && (
          <div className="scholarships">
            <p className="scholarship">
              Congratulations! As a 21st Century Scholar you qualify for a Full
              Tuition Scholarship!
            </p>
            <hr />
            <div className="scholarship">Potential Total: ${ scholarshipResults.value }</div>
          </div>
        )}
        {scholarshipResults.scholarships[0] === "Good Neighbor Scholarship" && (
          <div className="scholarships">
            <p className="scholarship">
              Congratulations! You may qualify for our Good Neighbor Scholarship, which is the largest scholarship offered to students who plan to live within 60 miles of campus!
            </p>
            <hr />
            <div className="scholarship">Potential Total: ${ scholarshipResults.value }</div>
          </div>
        )}
        {scholarshipResults.scholarships[0] !== "21st Century Scholar" && scholarshipResults.scholarships[0] !== "Good Neighbor Scholarship" && (
          <div className="scholarships">
            {scholarshipResults.scholarships.map((scholarship, idx) => {
              return (
                <div className="scholarship" key={idx}>
                  {scholarship === "Athletic Scholarship" ? <p>Athletic Scholarship<sup>**</sup></p> : scholarship }
                </div>
              );
            })}
            <hr />
            <div className="scholarship">Potential Total: ${ scholarshipResults.value }</div>
          </div>
        )}
        <div className="disclaimers">
          <p>
            <sup>*</sup> The estimated amount stated here does not guarantee receipt of these
            funds.<br />
            {scholarshipResults.scholarships.includes("Athletic Scholarship") && (
              <span><sup>**</sup> You will need to contact the coach in the specific sport in which you are interested to learn more about athletic scholarship amounts.</span>
            )}
          </p>
        </div>
      </div>
      <div className="mb-32px">
        <h3>Apply Now and Lock in Your Savings!</h3>
        <Button
          type="primary"
          value="Apply Now"
          onClick={() => {
            window.location.href = "https://www.oak.edu/admissions/apply-now/";
          }}
        />
      </div>
      <div className="followups">
        <h4>Need Help?</h4>
        <p>
          Contact the Office of Admissions at{" "}
          <a href="mailto:ocuadmit@oak.edu">ocuadmit@oak.edu</a> or{" "}
          <a href="tel:800.737.5125">(800) 737-5125</a>
        </p>
        <h4>Don't Forget About the FAFSA</h4>
        <p>
          You may qualify for additional federal and state funding. Please
          complete your FAFSA using OCU’s school code (001824) to determine your
          eligibility.
        </p>
      </div>
    </div>
  );
}
