import React from "react";
import { Button, Select, TextField } from "./Form";

export default function AboutMe({
  activePage,
  errors,
  handleBackwardClick,
  submitting,
}) {
  return (
    <div className={`form-page${activePage === 2 ? " active" : ""}`}>
      <h3>Almost There!</h3>
      <p>
        Introduce yourself! Before we share your results, please complete the
        form below.
      </p>
      <div>
        <TextField
          name="firstName"
          label="First Name"
          error={errors.firstName}
        />
        <TextField name="lastName" label="Last Name" error={errors.lastName} />
        <TextField name="email" label="Email" error={errors.email} />
        <div className="input-group">
          <label className="form-label">Birth Date</label>
          <input type="date" name="birthDate" id="birthDate" required />
          {errors.birthDate && (
            <span className="error">{errors.birthDate}</span>
          )}
        </div>
        <Select
          name="startSemester"
          label="When are you interested in starting?"
          error={errors.startSemester}
          options={{
            "...": "",
            "Spring 2022": "Spring 2022",
            "Fall 2022": "Fall 2022",
            "Spring 2023": "Spring 2023",
            "Fall 2023": "Fall 2023",
            "Spring 2024": "Spring 2024",
            "Fall 2024": "Fall 2024",
          }}
        />
        <Button
          type={submitting ? "disabled" : "primary"}
          value={submitting ? "Submitting..." : "Get My Results"}
        />
        {!submitting && (
          <Button
            type="secondary"
            value="< Back"
            onClick={handleBackwardClick}
          />
        )}
      </div>
    </div>
  );
}
