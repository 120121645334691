import React, { useState } from "react";
import submitToSlate from "./submitToSlate";
import validateForm from "./validateForm";
import AboutMe from "./components/AboutMe";
import Results from "./components/Results";
import ScholarshipQuestions from "./components/ScholarshipQuestions";
import Indicators from "./components/Indicators";
import Logo from "./images/logo.png";
import ArrowBackground from "./images/arrow-background.png";
import BackgroundPhoto from "./images/background-photo.png";

function App() {
  const [activePage, setActivePage] = useState(1);
  const [errors, setErrors] = useState({});
  const [scholarships, setScholarships] = useState({scholarships: [], value: ""});
  const [submitting, setSubmitting] = useState(false);

  const handleForwardClick = (e) => {
    e.preventDefault();

    validateForm(() => {
      setActivePage(activePage + 1);

      document.querySelector('#reset-scroll-target').scrollIntoView();
    }, setErrors);

  };

  const handleBackwardClick = (e) => {
    e.preventDefault();
    setActivePage(activePage - 1);

    document.querySelector('#reset-scroll-target').scrollIntoView();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm(() => {
      setSubmitting(true);

      submitToSlate(e.target, (scholarshipResults) => {
        setSubmitting(false);
        setScholarships(scholarshipResults);
        setActivePage(3);

        document.querySelector('#reset-scroll-target').scrollIntoView();
      });
    }, setErrors);
  };

  return (
    <div>
      <div className="logo-container">
        <img src={Logo} className="logo" alt="Oakland City University Logo" />
      </div>
      <div className="main">
        <div className="bg-container stripes">
          <img src={ArrowBackground} alt="" />
        </div>
        <div className="container">
          <div className="title-box">
            <h1>
              What's your <br />
              True OCU Cost?
            </h1>
            <h2>Find out with Our Scholarship Calculator</h2>
          </div>
          <div className="title-box-arrow"></div>
          <div id="reset-scroll-target" className="form-container">
            <form onSubmit={handleSubmit} noValidate>
              <ScholarshipQuestions
                activePage={activePage}
                errors={errors}
                handleForwardClick={handleForwardClick}
              />
              <AboutMe
                activePage={activePage}
                errors={errors}
                handleBackwardClick={handleBackwardClick}
                handleSubmit={handleSubmit}
                submitting={submitting}
              />
            </form>
            <Results activePage={activePage} scholarshipResults={scholarships} />
            <Indicators activePage={activePage} />
          </div>
        </div>
        <div className="bg-container bg-photo">
          <img src={BackgroundPhoto} alt="Oakland City University's Campus" />
        </div>
      </div>
    </div>
  );
}

export default App;
