export default function validateForm(success, failure) {
  let errors = {};
  let inputs = document.querySelectorAll(
    ".form-page.active input, .form-page.active select"
  );

  for (let input of inputs) {
    if (input.required && !input.value) {
      errors[input.id] = "This field is required.";
    }
  }
  if (Object.keys(errors).length === 0) {
    success();
  } else {
    failure(errors);
  }
}
