export default function calculateScholarships(data) {
  let scholarships = [];
  let value = 0;

  if (data.scholar) {
    scholarships.push("21st Century Scholar");
    value = "25,740";
  }

  if (!data.scholar && data.housing === "off-campus") {
    scholarships.push("Good Neighbor Scholarship");
    value = "14,240";
  }

  if (!data.scholar && data.housing === "campus") {
    let calcVal = 0;

    scholarships.push(academicTiers[data.gpa]);

    if (data.gpa === "3.75 - 4.0") {
      calcVal = 19000;
    }
    if (data.gpa === "3.25 - 3.74") {
      calcVal = 18000;
    }
    if (data.gpa === "2.75 - 3.24") {
      calcVal = 17500;
    }
    if (data.gpa === "< 2.75") {
      calcVal = 17000;
    }

    for (let item in data) {
      if (data[item] && scholarshipOptions[item]) {
        scholarships.push(scholarshipOptions[item]);
        calcVal = calcVal + 500;
      }
    }

    if (calcVal > 20000) { // Max is 20000
      calcVal = 20000;
    }
    if (calcVal < 17000) { // Min is 17000
      calcVal = 17000;
    }

    value = calcVal.toLocaleString('en-US');
  }

  return {
    scholarships: scholarships,
    value: value,
  }
}

const academicTiers = {
  "3.75 - 4.0": "Academic Tier 1 Scholarship",
  "3.25 - 3.74": "Academic Tier 2 Scholarship",
  "2.75 - 3.24": "Academic Tier 3 Scholarship",
  "< 2.74": "Academic Tier 4 Scholarship",
};

const scholarshipOptions = {
  baptist: "General Baptist Scholarship",
  homeSchooled: "Home School Scholarship",
  christianSchool: "Christian HS Scholarship",
  legacy: "Alumni Scholarship",
  athlete: "Athletic Scholarship",
  international: "International Scholarship",
  military: "Military Scholarship",
  diverse: "Diversity Scholarship",
};
