import calculateScholarships from "./calculateScholarships";
import axios from "axios";

export default function submitToSlate(formData, callback) {
  let scholarshipData = {
    gpa: formData.gpa.value,
    housing: formData.housing.value,
    baptist: formData.baptist.checked,
    homeSchooled: formData.homeSchooled.checked,
    christianSchool: formData.christianSchool.checked,
    scholar: formData.scholar.checked,
    legacy: formData.legacy.checked,
    athlete: formData.athlete.checked,
    international: formData.international.checked,
    military: formData.military.checked,
    diverse: formData.diverse.checked,
  };

  let studentData = {
    firstName: formData.firstName.value,
    lastName: formData.lastName.value,
    email: formData.email.value,
    birthdate: formData.birthDate.value,
    expectedentranceterm: formData.startSemester.value,
  };

  axios.post("https://slate.mediacross.com/ocu-truecost/update", studentData).then((res) => {
      let scholarships = calculateScholarships(scholarshipData);
      callback(scholarships);
  });
}
