import React from "react";
import { Button, CheckBox, Select } from "./Form";

export default function ScholarshipQuestions({
  activePage,
  errors,
  handleForwardClick,
}) {
  return (
    <div className={`form-page${activePage === 1 ? " active" : ""}`}>
      <h3>Awards Ranging from $100 up to Full Tuition</h3>
      <p>
        Here’s the truth: you’ll pay far less to attend OCU than you think.
        Another truth? You deserve a personalized, affordable education at a
        place that truly gets you.
      </p>
      <p>
        Are you going to be a traditional main campus student? Excellent! Give
        us a little information below, and let’s see what kind of scholarship
        money is in your future.
      </p>
      <div>
        <Select
          name="gpa"
          label="What's your GPA?"
          options={{
            "...": "",
            "3.75 - 4.0": "3.75 - 4.0",
            "3.25 - 3.74": "3.25 - 3.74",
            "2.75 - 3.24": "2.75 - 3.24",
            "< 2.74": "< 2.74",
          }}
          error={errors.gpa}
        />
        <Select
          name="housing"
          label="Where do you plan to live?"
          options={{
            "...": "",
            "I will live on campus.": "campus",
            "I will live with a parent/guardian within 60 miles of campus.":
              "off-campus",
          }}
          error={errors.housing}
        />
        <div className="input-group">
          <label className="form-label">Select all that apply to you.</label>
          <div className="checkbox-list">
            <CheckBox
              name="baptist"
              value="baptist"
              label="I attend a General Baptist Church."
            />
            <CheckBox
              name="homeSchooled"
              value="home schooled"
              label="I was home schooled while completing high school."
            />
            <CheckBox
              name="christianSchool"
              value="christian school"
              label="I attended a Christian high school."
            />
            <CheckBox
              name="scholar"
              value="21st century scholar"
              label="I am a 21st Century Scholar."
            />
            <CheckBox
              name="legacy"
              value="legacy"
              label="My parent, grandparent, sibling, or spouse graduated from OCU."
            />
            <CheckBox
              name="athlete"
              value="athlete"
              label="I am participating in OCU Athletics."
            />
            <CheckBox
              name="international"
              value="international"
              label="I am an international student."
            />
            <CheckBox
              name="military"
              value="military"
              label="I served in the military."
            />
            <CheckBox
              name="diverse"
              value="diverse"
              label="I am a student from a diverse or multicultural background."
            />
          </div>
        </div>
        <Button
          type="primary"
          value="Next: About Me"
          onClick={handleForwardClick}
        />
      </div>
    </div>
  );
}
